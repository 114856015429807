import { Square3Stack3DIcon } from '@heroicons/react/16/solid';
import { Badge, type BadgeProps, Text } from '@mantine/core';
import { Link } from 'react-router-dom';

interface Series {
  id: number;
  name: string;
}

type Props = BadgeProps & {
  series?: Series | null;
  showIcon?: boolean;
  showOnlyName?: boolean;
};

export default function SeriesBadge({ series, showIcon = true, showOnlyName = false, ...props }: Props) {
  // TODO: Figure out why this is an empty object when no series is assigned
  if (!series || !series.id) {
    return (
      <Text c="dimmed" size="sm">
        No series selected
      </Text>
    );
  }

  const content = showOnlyName ? (
    series.name
  ) : (
    <span>
      Part of the <strong>{series.name}</strong> series
    </span>
  );

  return (
    <Badge
      color="gray"
      component={Link}
      to={`/series/${series.id}`}
      radius="sm"
      variant="light"
      leftSection={showIcon ? <Square3Stack3DIcon width={16} /> : null}
      style={{ cursor: 'pointer' }}
      {...props}
    >
      {content}
    </Badge>
  );
}
