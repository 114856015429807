import { Paper, type PaperProps, useMantineColorScheme } from '@mantine/core';
import type React from 'react';

type Props = PaperProps & {
  children: React.ReactNode;
};

export default function Sheet({ children, ...props }: Props) {
  const { colorScheme } = useMantineColorScheme();

  const colour = colorScheme === 'dark' ? 'rgba(251, 252, 254, 0.075)' : 'rgba(251, 252, 254)';

  return (
    <Paper radius="md" withBorder bg={colour} {...props}>
      {children}
    </Paper>
  );
}
