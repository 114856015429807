import { Box, Container, type MantineSize, Stack } from '@mantine/core';
import type React from 'react';
import LoadingScreen from '../LoadingScreen';

interface PageProps {
  loading?: boolean;
  maxWidth?: MantineSize;
  title: React.ReactNode;
  children: React.ReactNode;
}

export default function Page({ loading = false, maxWidth = 'xl', title, children }: PageProps) {
  const child = loading ? <LoadingScreen /> : children;

  return (
    <Container size={maxWidth}>
      <Stack gap={16}>
        <Box>{title}</Box>
        <Box>{child}</Box>
      </Stack>
    </Container>
  );
}
