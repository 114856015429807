import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { type Entry, fetcher } from '../../api.ts';
import { EntryEdit } from '../EntryScreen/EntryEdit.tsx';
import ErrorScreen from '../ErrorScreen.tsx';
import LoadingScreen from '../LoadingScreen';
import Page from '../Page';
import PageTitle from '../PageTitle';

type EntryEditParams = {
  id: string;
};

export default function EntryEditScreen() {
  const { id } = useParams<EntryEditParams>() as EntryEditParams;
  const query = useQuery<Entry | undefined>({
    queryKey: ['api', 'v1', 'entries', id],
    queryFn: () => fetcher(`/api/v1/entries/${id}`),
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchOnReconnect: false,
  });

  if (query.isLoading) {
    return <LoadingScreen />;
  }

  if (query.isError) {
    return <ErrorScreen error={query.error} />;
  }

  const entry = query.data;
  if (!entry) {
    // TODO
    return <div>Entry not found</div>;
  }

  return (
    <Page maxWidth="xl" title={<PageTitle title="Edit Entry" />}>
      <EntryEdit
        id={entry.id}
        content={entry.content}
        serial={entry.series?.id ?? null}
        tags={entry.tags.map((t) => t.id)}
        title={entry.title}
        type={entry.type}
      />
    </Page>
  );
}
