import { Center, Group, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import type React from 'react';

interface Props {
  actions?: React.ReactNode;
  fullHeight?: boolean;
  subtitle: string;
  title: string;
  Icon: React.ElementType;
}

export default function NoItemsFound({ actions, fullHeight, subtitle, title, Icon }: Props) {
  const theme = useMantineTheme();

  const height = fullHeight ? '50vh' : undefined;

  return (
    <Center maw={600} h={height} mx="auto" p={16}>
      <Stack align="center">
        <Icon color={theme.colors.gray[6]} width={64} />

        <Title order={3}>{title}</Title>

        <Text c="dimmed" ta="center">
          {subtitle}
        </Text>

        {actions && (
          <Group grow preventGrowOverflow={false}>
            {actions}
          </Group>
        )}
      </Stack>
    </Center>
  );
}
