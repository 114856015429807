import { BookOpenIcon, CalculatorIcon, CalendarIcon, DocumentTextIcon, TagIcon } from '@heroicons/react/24/outline';
import { PencilIcon } from '@heroicons/react/24/solid';
import { Button, Flex, Grid, Group, Stack, Text, Tooltip } from '@mantine/core';
import pluralize from 'pluralize';
import type { SVGProps } from 'react';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';
import Sheet from '../../Sheet.tsx';
import type { Entry } from '../../api.ts';
import { useAuth } from '../../hooks/useAuth.tsx';
import { formatEntryTitle } from '../../utils.ts';
import Page from '../Page';
import PageTitle from '../PageTitle';
import SeriesBadge from '../SeriesBadge.tsx';
import TagBadge from '../TagBadge.tsx';

interface EntryMetaItemProps {
  children: React.ReactNode;
  tooltip: string;
  Icon: React.ElementType<SVGProps<SVGSVGElement>>;
}

function EntryMetaItem({ children, tooltip, Icon }: EntryMetaItemProps) {
  return (
    <Grid align="center" justify="center">
      <Grid.Col span="content">
        <Tooltip label={tooltip}>
          <Flex align="center">
            <Icon width={18} />
          </Flex>
        </Tooltip>
      </Grid.Col>

      <Grid.Col span="auto">{children}</Grid.Col>
    </Grid>
  );
}

interface EntryViewProps {
  entry?: Entry;
}

export const EntryView = ({ entry }: EntryViewProps) => {
  const { accessToken } = useAuth();

  if (entry) {
    return (
      <Page
        title={
          <Group justify="space-between" align="center">
            <PageTitle title={formatEntryTitle(entry)} />

            <Group>
              {/* TODO: This should not have the access token, as users may e.g. copy paste the link which will give access to their account */}
              <Button
                variant="outline"
                component="a"
                target="_blank"
                href={`/api/v1/entries/${entry.id}/pdf?token=${accessToken}`}
                leftSection={<DocumentTextIcon width={16} />}
              >
                View as PDF
              </Button>

              <Button component={Link} to={`/entries/${entry.id}/edit`} leftSection={<PencilIcon width={16} />}>
                Edit
              </Button>
            </Group>
          </Group>
        }
      >
        <Stack>
          <Sheet p={16}>
            <Stack gap={8}>
              <EntryMetaItem Icon={CalendarIcon} tooltip="Entry date">
                <Text size="sm">{entry.date}</Text>
              </EntryMetaItem>

              <EntryMetaItem Icon={CalculatorIcon} tooltip="Word count">
                <Text size="sm">
                  {entry.word_count} {pluralize('word', entry.word_count)}
                </Text>
              </EntryMetaItem>

              <EntryMetaItem Icon={BookOpenIcon} tooltip="Entry series">
                <SeriesBadge showIcon={false} showOnlyName={true} series={entry.series} />
              </EntryMetaItem>

              <EntryMetaItem Icon={TagIcon} tooltip="Entry tags">
                <TagBadge.Group tags={entry.tags} />
              </EntryMetaItem>
            </Stack>
          </Sheet>

          <Text size="sm">
            <Markdown>{entry.content}</Markdown>
          </Text>
        </Stack>
      </Page>
    );
  }

  // TODO: This is basically the 404 screen now
  return (
    <>
      <h3>🙁 Nothing here</h3>
      <p>
        You didn't make an entry on this date! Make sure you've <Link to="/"> written something for today</Link>.
      </p>
    </>
  );
};
