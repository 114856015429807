import { Badge, Text, Tooltip } from '@mantine/core';
import { format } from 'date-fns';
import pluralize from 'pluralize';
import type { EntrySeries, EntryTag } from '../api.ts';
import { formatEntryTitle } from '../utils.ts';
import SeriesBadge from './SeriesBadge.tsx';
import { StackedListItem } from './StackedListItem.tsx';
import TagBadge from './TagBadge.tsx';

interface Props {
  entry: {
    id: number;
    title: string | null;
    extract: string;
    date: string;
    word_count: number;
    tags: EntryTag[];
    series: EntrySeries | null;
    created_at: string;
  };
}

export default function EntryListItem({ entry }: Props) {
  return (
    <StackedListItem
      href={`/entries/${entry.id}`}
      // TODO: This title not descriptive or precise enough
      title={
        <Tooltip label={`Created ${format(entry.created_at, "'around' h a 'on' EEEE do MMMM yyyy")}`}>
          <span>{formatEntryTitle(entry)}</span>
        </Tooltip>
      }
      titleRight={
        <Badge color="gray" radius="sm" variant="light">
          {entry.word_count} {pluralize('word', entry.word_count)}
        </Badge>
      }
      subtitleRight={<TagBadge.Group tags={entry.tags} />}
      subtitle={<SeriesBadge series={entry.series} />}
    >
      <Text size="sm" lineClamp={2}>
        {entry.extract}
      </Text>
    </StackedListItem>
  );
}
