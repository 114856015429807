/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'eu-west-1',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_d94LNkjsJ',
  aws_user_pools_web_client_id: '29mfubvgmt5h911hlqc96ld8u1',
  oauth: {
    domain: 'writual-development.auth.eu-west-1.amazoncognito.com',
  },
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['NAME', 'EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['TOTP'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: ['REQUIRES_LOWERCASE', 'REQUIRES_UPPERCASE', 'REQUIRES_NUMBERS'],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
};

export default awsmobile;
