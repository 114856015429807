import { Box, Group, Paper, Stack, Title } from '@mantine/core';
import type React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface StackedListItemProps {
  children?: React.ReactNode;
  href: string;
  title: React.ReactNode;
  titleRight?: React.ReactNode;
  subtitle: React.ReactNode;
  subtitleRight?: React.ReactNode;
}

export function StackedListItem({ children, href, title, titleRight, subtitle, subtitleRight }: StackedListItemProps) {
  return (
    // TODO: Hover colour
    <Paper my={12} p={0} radius="md" withBorder>
      <Box p={10}>
        <Box component={RouterLink} to={href}>
          <Stack gap={12} p={6} w="100%">
            <Group justify="space-between">
              <Title order={5}>{title}</Title>

              {titleRight}
            </Group>

            {children}

            <Group gap={12} justify="space-between">
              <div>{subtitle}</div>

              {subtitleRight}
            </Group>
          </Stack>
        </Box>
      </Box>
    </Paper>
  );
}
