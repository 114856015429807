import {
  BookOpenIcon as BookOpenIconSmall,
  CalendarIcon as CalendarIconSmall,
  MagnifyingGlassIcon,
  PencilIcon as PencilIconSmall,
  PencilSquareIcon as PencilSquareIconSmall,
  PlusIcon,
  SparklesIcon as SparklesIconSmall,
  StarIcon,
} from '@heroicons/react/16/solid';
import { BookOpenIcon, DocumentTextIcon, PencilSquareIcon, TrophyIcon } from '@heroicons/react/24/outline';
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Grid,
  Group,
  List,
  LoadingOverlay,
  Paper,
  Progress,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { formatDistanceToNow } from 'date-fns';
import pluralize from 'pluralize';
import type React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Sheet from '../../Sheet.tsx';
import { type EntryListEntry, type EntryMeta, type Goal, type Items, type Stats, fetcher } from '../../api.ts';
import { formatEntryTitle } from '../../utils.ts';
import ErrorScreen from '../ErrorScreen.tsx';
import { getGoalProgress } from '../GoalsScreen/goalHelpers.ts';
import Page from '../Page';
import PageTitle from '../PageTitle';

export default function TodayScreen() {
  return (
    <Page
      title={
        <Group justify="space-between" align="center">
          <PageTitle title="Dashboard" />

          <Group>
            <ToolbarAction to="/entries/new" tooltip="Start a new entry" Icon={PencilSquareIcon} />
            <ToolbarAction to="/series" tooltip="Work on a series" Icon={BookOpenIcon} />
            <ToolbarAction to="/entries" tooltip="Review entries" Icon={DocumentTextIcon} />
            <ToolbarAction to="/goals" tooltip="Check your goals" Icon={TrophyIcon} />
          </Group>
        </Group>
      }
    >
      <Grid align="stretch">
        <Grid.Col span={{ base: 12, md: 9 }}>
          <RecentEntries />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 3 }}>
          <WritingStats />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 8 }}>
          <GoalProgress />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 4 }}>
          <QuoteOfTheDay />
        </Grid.Col>
      </Grid>
    </Page>
  );
}

function RecentEntries() {
  const theme = useMantineTheme();

  const [query, setQuery] = useState('');
  const entriesQuery = useQuery<Items<EntryListEntry, EntryMeta>>({
    queryKey: ['api', 'v1', 'entries'],
    queryFn: () => fetcher('/api/v1/entries'),
  });

  const recentEntries = (entriesQuery.data?.items || [])
    .filter((entry) => {
      const title = entry.title || '';
      const date = entry.date;

      if (query === '') {
        return true;
      }

      if (title.toLowerCase().includes(query.toLowerCase())) {
        return true;
      }

      if (date.toLowerCase().includes(query.toLowerCase())) {
        return true;
      }
    })
    .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())
    .slice(0, 3);

  return (
    <Sheet pos="relative" display="flex" h="100%" p={24} style={{ flexDirection: 'column' }}>
      <Group align="center" justify="space-between" mb={16}>
        <Title order={3}>Recent entries</Title>

        <Text component={Link} to="/entries" c="blue" size="sm">
          View all entries
        </Text>
      </Group>

      <LoadingOverlay visible={entriesQuery.isLoading} />

      {entriesQuery.isError && <ErrorScreen error={entriesQuery.error} />}

      {entriesQuery.data && entriesQuery.data?.items.length === 0 && (
        <Paper h="100%" py={32} p={16} radius="md" withBorder>
          <Center h="100%" ta="center">
            <Stack>
              <Box>
                <PencilSquareIconSmall width={24} />
                <Title order={4}>Your writing journey begins here!</Title>

                <Text size="sm">Start crafting your first entry and watch your collection grow.</Text>
              </Box>

              <Text size="sm" fw={500}>
                Here's how to get started:
              </Text>

              <List center spacing="xs" size="sm">
                <List.Item icon={<PencilIconSmall color={theme.colors.blue[5]} width={16} />}>
                  Write a short story or poem
                </List.Item>
                <List.Item icon={<BookOpenIconSmall color={theme.colors.green[5]} width={16} />}>
                  Start a chapter of your novel
                </List.Item>
                <List.Item icon={<SparklesIconSmall color={theme.colors.grape[5]} width={16} />}>
                  Jot down a creative idea
                </List.Item>
              </List>

              <Box>
                <Button component={Link} to="/entries/new" variant="default" leftSection={<PlusIcon width={16} />}>
                  Create your first entry
                </Button>
              </Box>
            </Stack>
          </Center>
        </Paper>
      )}

      {(query || recentEntries.length > 0) && (
        <Box>
          <TextInput
            leftSection={<MagnifyingGlassIcon width={16} />}
            onChange={(e) => setQuery(e.currentTarget.value)}
            placeholder="Search entries..."
          />
          <Stack mt={16}>
            {recentEntries.map((entry) => {
              return (
                <Box component={Link} key={entry.id} to={`/entries/${entry.id}`} py={4}>
                  <Title order={5}>{formatEntryTitle(entry)}</Title>
                  <Text lineClamp={2} size="sm" mt={4}>
                    {entry.extract}
                  </Text>
                  <Group justify="space-between" mt={8}>
                    <Text c="dimmed" size="xs">
                      {entry.date}
                    </Text>

                    <Text c="dimmed" size="xs" title={entry.updated_at}>
                      Last updated {formatDistanceToNow(entry.updated_at)} ago
                    </Text>
                  </Group>
                </Box>
              );
            })}
          </Stack>
        </Box>
      )}
    </Sheet>
  );
}

function WritingStats() {
  const statsQuery = useQuery<Stats>({
    queryKey: ['api', 'v1', 'stats'],
    queryFn: () => fetcher('/api/v1/stats'),
  });

  const currentStreak = statsQuery.data?.current_streak ? statsQuery.data?.current_streak : 0;
  const longestStreak = statsQuery.data?.longest_streak ? statsQuery.data?.longest_streak : 0;
  const totalEntries = statsQuery.data?.total_entries ? statsQuery.data?.total_entries : 0;
  const totalWords = statsQuery.data?.total_words ? statsQuery.data?.total_words : 0;
  const longestEntry = statsQuery.data?.longest_entry ? statsQuery.data?.longest_entry : 0;
  const shortestEntry = statsQuery.data?.shortest_entry ? statsQuery.data?.shortest_entry : 0;

  return (
    <Sheet pos="relative" h="100%" p={16}>
      <Title order={3} mb={16}>
        Writing stats
      </Title>

      <LoadingOverlay visible={statsQuery.isLoading} />

      {statsQuery.isError && <ErrorScreen error={statsQuery.error} />}

      {statsQuery.data && (
        <Box mt={16}>
          <StatEntry title="Current streak" value={`${currentStreak} ${pluralize('day', currentStreak)}`} />
          <StatEntry title="Longest streak" value={`${longestStreak} ${pluralize('day', longestStreak)}`} />
          <StatEntry title="Total entries" value={`${totalEntries}`} />
          <StatEntry title="Total words" value={`${totalWords}`} />
          <StatEntry title="Longest entry" value={`${longestEntry} ${pluralize('word', longestEntry)}`} />
          <StatEntry title="Shortest entry" value={`${shortestEntry} ${pluralize('word', shortestEntry)}`} />
        </Box>
      )}
    </Sheet>
  );
}

function GoalProgress() {
  const theme = useMantineTheme();

  const goalsQuery = useQuery<Items<Goal, void>>({
    queryKey: ['api', 'v1', 'goals'],
    queryFn: () => fetcher('/api/v1/goals'),
  });

  const goals = goalsQuery.data?.items || [];

  return (
    <Sheet pos="relative" display="flex" h="100%" p={24} style={{ flexDirection: 'column' }}>
      <Title order={3} mb={16}>
        Goal progress
      </Title>

      <LoadingOverlay visible={goalsQuery.isLoading} />

      {goalsQuery.isError && <ErrorScreen error={goalsQuery.error} />}

      {goalsQuery.data && goals.length === 0 && (
        <Paper h="100%" py={32} p={16} radius="md" withBorder>
          <Center h="100%" ta="center">
            <Stack>
              <Box>
                <TrophyIcon width={24} />
                <Title order={5}>Ready to elevate your writing?</Title>

                <Text size="sm">Set meaningful goals to track your progress and stay motivated.</Text>
              </Box>

              <Text size="sm" fw={500}>
                Try setting some goals like:
              </Text>

              <List center spacing="xs" size="sm">
                <List.Item icon={<StarIcon color={theme.colors.yellow[5]} width={16} />}>
                  Write 500 words daily
                </List.Item>
                <List.Item icon={<CalendarIconSmall color={theme.colors.green[5]} width={16} />}>
                  Complete a chapter weekly
                </List.Item>
                <List.Item icon={<BookOpenIconSmall color={theme.colors.indigo[5]} width={16} />}>
                  Finish your novel in 6 months
                </List.Item>
              </List>

              <Box>
                <Button component={Link} to="/goals/new" variant="default" leftSection={<PlusIcon width={16} />}>
                  Set your first goal
                </Button>
              </Box>
            </Stack>
          </Center>
        </Paper>
      )}

      {goals.length > 0 && (
        <Stack>
          {goals.map((goal) => {
            const progress = getGoalProgress(goal);

            return (
              <Box key={goal.id} py={4}>
                <Group align="center" justify="space-between">
                  <Text size="sm" fw="bold">
                    {progress.title}
                  </Text>
                  <Text lineClamp={2} size="sm" mt={4}>
                    {progress.subtitle}
                  </Text>
                </Group>

                <Progress mt={8} value={progress.progress} variant="soft" />
              </Box>
            );
          })}
        </Stack>
      )}
    </Sheet>
  );
}

function QuoteOfTheDay() {
  return (
    <Sheet pos="relative" h="100%" p={16}>
      <Title order={3} mb={16}>
        Quote of the day
      </Title>

      <Center>
        <Text component="blockquote">
          <Text component="p" fs="italic" mb={8}>
            "The first draft is just you telling yourself the story."
          </Text>

          <Text component="footer" size="xs" ta="right">
            — Terry Pratchett
          </Text>
        </Text>
      </Center>
    </Sheet>
  );
}

interface StatEntryProps {
  extra?: React.ReactNode;
  title: string;
  value: string;
}

function StatEntry({ title, value }: StatEntryProps) {
  return (
    <Grid.Col px={0} span={6}>
      <Stack gap={2}>
        <Text size="sm" c="dimmed" fw={500}>
          {title}
        </Text>

        <Text size="lg" fw={600}>
          {value}
        </Text>
      </Stack>
    </Grid.Col>
  );
}

interface ToolbarActionProps {
  to: string;
  tooltip: string;
  Icon: React.ElementType;
}

function ToolbarAction({ to, tooltip, Icon }: ToolbarActionProps) {
  return (
    <Tooltip label={tooltip}>
      <ActionIcon component={Link} to={to} variant="default" size="lg" aria-label={tooltip}>
        <Icon width={20} />
      </ActionIcon>
    </Tooltip>
  );
}
