import { EntryEdit } from '../EntryScreen/EntryEdit';
import Page from '../Page';
import PageTitle from '../PageTitle';

export default function NewEntryScreen() {
  return (
    <Page maxWidth="xl" title={<PageTitle title="New Entry" />}>
      <EntryEdit id={null} content={null} serial={null} tags={[]} title={null} type={null} />
    </Page>
  );
}
