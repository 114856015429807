import { format, isEqual, startOfMonth, startOfWeek } from 'date-fns';
import pluralize from 'pluralize';
import type { Goal } from '../../api.ts';
import { formatDate, getToday } from '../../utils.ts';

interface GoalProgress {
  completed: boolean;
  progress: number;
  subtitle: string;
  title: string;
}

function getEntryCountGoalProgress(goal: Goal): GoalProgress {
  const today = new Date(format(new Date(getToday()), 'yyyy-MM-dd'));
  const target = Number.parseInt(goal.target);

  let current = 0;
  if (goal.recurrence === 'daily') {
    const thisDay = goal.progress.find((p) => isEqual(new Date(p.date), today));
    current = thisDay?.count || 0;
  } else if (goal.recurrence === 'weekly') {
    const weekStart = startOfWeek(today, { weekStartsOn: 1 });
    const thisWeek = goal.progress.find((p) => isEqual(new Date(p.date), weekStart));
    current = thisWeek?.count || 0;
  } else if (goal.recurrence === 'monthly') {
    const monthStart = startOfMonth(today);
    const thisMonth = goal.progress.find((p) => isEqual(new Date(p.date), monthStart));
    current = thisMonth?.count || 0;
  }

  const progress = Math.min(100, (100 / target) * current);
  const isCompleted = current >= target;

  return {
    completed: isCompleted,
    progress: progress,
    subtitle: `${current}/${target} ${pluralize('entry', target)} so far ${convertToCurrentTimeRange(goal.recurrence)}`,
    title: `Complete ${goal.target} ${pluralize('entry', target)} per ${convertToRecurrence(goal.recurrence)}`,
  };
}

function getProjectCompletionGoalProgress(goal: Goal): GoalProgress {
  const isCompleted = goal.progress.length > 0;
  const progress = isCompleted ? 100 : 0;

  return {
    completed: isCompleted,
    progress: progress,
    subtitle: isCompleted ? `Completed on ${formatDate(goal.progress[0].date)}` : 'Not completed yet',
    title: `Finish the project "${goal.target}" by ${formatDate(goal.ends_at)}`,
  };
}

function getWordCountGoalProgress(goal: Goal): GoalProgress {
  const today = new Date(format(new Date(getToday()), 'yyyy-MM-dd'));
  const targetWordCount = Number.parseInt(goal.target);

  let currentWordCount = 0;
  if (goal.recurrence === 'daily') {
    const todaysProgress = goal.progress.find((p) => isEqual(new Date(p.date), today));
    currentWordCount = todaysProgress?.count || 0;
  } else if (goal.recurrence === 'weekly') {
    const weekStart = startOfWeek(today, { weekStartsOn: 1 });
    const thisWeek = goal.progress.find((p) => isEqual(new Date(p.date), weekStart));
    currentWordCount = thisWeek?.count || 0;
  } else if (goal.recurrence === 'monthly') {
    const monthStart = startOfMonth(today);
    const thisMonth = goal.progress.find((p) => isEqual(new Date(p.date), monthStart));
    currentWordCount = thisMonth?.count || 0;
  }

  const progress = Math.min(100, (100 / targetWordCount) * currentWordCount);
  const isCompleted = currentWordCount >= targetWordCount;

  return {
    completed: isCompleted,
    progress: progress,
    subtitle: `${currentWordCount}/${targetWordCount} words so far ${convertToCurrentTimeRange(goal.recurrence)}`,
    title: `Write ${goal.target} words per ${convertToRecurrence(goal.recurrence)}`,
  };
}

export function getGoalProgress(goal: Goal): GoalProgress {
  switch (goal.type) {
    case 'project_completion':
      return getProjectCompletionGoalProgress(goal);
    case 'word_count':
      return getWordCountGoalProgress(goal);
    case 'entry_count':
      return getEntryCountGoalProgress(goal);
  }
}

function convertToRecurrence(recurrence: string): string {
  if (recurrence === 'daily') {
    return 'day';
  }

  if (recurrence === 'weekly') {
    return 'week';
  }

  if (recurrence === 'monthly') {
    return 'month';
  }

  // TODO
  return '';
}

function convertToCurrentTimeRange(recurrence: string): string {
  if (recurrence === 'daily') {
    return 'today';
  }

  if (recurrence === 'weekly') {
    return 'this week';
  }

  if (recurrence === 'monthly') {
    return 'this month';
  }

  // TODO
  return '';
}
