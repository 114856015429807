import './app.css';
import { Authenticator } from '@aws-amplify/ui-react';
import * as Sentry from '@sentry/react';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import { ProvideAuth } from './hooks/useAuth';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import { AppShell, MantineProvider, createTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Hub } from 'aws-amplify/utils';
import { useEffect } from 'react';

const queryClient = new QueryClient();

const theme = createTheme({
  fontFamily: 'Inter, sans-serif',
});

export function App() {
  const [opened, { toggle }] = useDisclosure();

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, message } }) => {
      console.log('auth', event, message);
    });
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={theme}>
        <Notifications />

        <Authenticator>
          {({ signOut }) => {
            if (!signOut) {
              Sentry.captureException(new Error('No sign out function was given. This should never happen!'));
              return <>No sign out function was given. This should never happen!</>;
            }

            return (
              <ProvideAuth>
                <AppShell
                  header={{ height: 70 }}
                  navbar={{ width: 300, breakpoint: 'sm', collapsed: { desktop: true, mobile: !opened } }}
                  padding="md"
                >
                  <Header navbarOpened={opened} navbarToggle={toggle} signOut={signOut} />

                  <AppShell.Main>
                    <Outlet />
                  </AppShell.Main>
                </AppShell>
              </ProvideAuth>
            );
          }}
        </Authenticator>
      </MantineProvider>
    </QueryClientProvider>
  );
}
