import { ExclamationTriangleIcon, HomeIcon } from '@heroicons/react/16/solid';
import { Button, Center, Group, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';

interface Props {
  error: Error;
}

export default function ErrorScreen({ error }: Props) {
  const location = useLocation();
  const theme = useMantineTheme();

  const mailTo = `mailto:support@writual.app?subject=${encodeURIComponent(`Error encountered on ${location.pathname}`)}&body=${encodeURIComponent(error.message)}`;

  return (
    <Center maw={600} h="50vh" mx="auto" p={16}>
      <Stack align="center">
        <ExclamationTriangleIcon color={theme.colors.red[6]} width={64} />

        <Title order={3}>Oops! Something went wrong</Title>

        <Text c="dimmed" ta="center">
          We're sorry, but it seems like Writual encountered an unexpected error.
        </Text>

        <Text c="dimmed" size="xs">
          Error details: {error.message}
        </Text>

        <Group grow preventGrowOverflow={false}>
          <Button component={Link} leftSection={<HomeIcon width={16} />} to="/" variant="default">
            Go to Homepage
          </Button>
        </Group>

        <Text c="dimmed" size="xs">
          If the problem persists, please{' '}
          <Text fw={700} component="a" href={mailTo}>
            contact support.
          </Text>
        </Text>
      </Stack>
    </Center>
  );
}
