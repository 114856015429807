import { Stack } from '@mantine/core';
import type React from 'react';

interface FormFieldsetProps {
  children: React.ReactNode;
}

export default function FormFieldset({ children }: FormFieldsetProps) {
  return <Stack gap={16}>{children}</Stack>;
}
