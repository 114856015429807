import { Title, type TitleProps } from '@mantine/core';

type PageTitleProps = TitleProps & {
  title: string;
};

export default function PageTitle({ title, ...props }: PageTitleProps) {
  return (
    <Title order={1} {...props}>
      {title}
    </Title>
  );
}
