import { PlusIcon } from '@heroicons/react/16/solid';
import { BookOpenIcon } from '@heroicons/react/24/outline';
import { Button, Grid, Group, MultiSelect, Select, Stack, Text } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useQuery } from '@tanstack/react-query';
import { isWithinInterval } from 'date-fns';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { type EntryListEntry, type EntryMeta, type Items, type Tag, fetcher } from '../../api';
import EntryListItem from '../EntryListItem.tsx';
import ErrorScreen from '../ErrorScreen.tsx';
import LoadingScreen from '../LoadingScreen';
import NoItemsFound from '../NoItemsFound.tsx';
import Page from '../Page';
import PageTitle from '../PageTitle';

type Sort = 'newest' | 'oldest' | 'most_words' | 'fewest_words';

export default function EntriesScreen() {
  const [selectedDateRange, setSelectedDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [selectedSort, setSelectedSort] = useState<Sort>('newest');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const clearFilters = () => {
    setSelectedDateRange([null, null]);
    setSelectedSort('newest');
    setSelectedTags([]);
  };

  const query = useQuery<Items<EntryListEntry, EntryMeta>>({
    queryKey: ['api', 'v1', 'entries'],
    queryFn: () => fetcher('/api/v1/entries'),
  });

  const tagsQuery = useQuery<Items<Tag, void>>({
    queryKey: ['api', 'v1', 'tags'],
    queryFn: () => fetcher('/api/v1/tags'),
  });

  if (query.isLoading) {
    return <LoadingScreen />;
  }

  if (query.isError) {
    return <ErrorScreen error={query.error} />;
  }

  if (!query.data) {
    return <div>There are no entries</div>;
  }

  const entries = query.data.items
    .filter((entry) => {
      if (!selectedDateRange[0] || !selectedDateRange[1]) {
        return true;
      }

      return isWithinInterval(entry.created_at, { start: selectedDateRange[0], end: selectedDateRange[1] });
    })
    .filter((entry) => {
      if (selectedTags.length === 0) {
        return true;
      }
      return entry.tags.some((tag) => selectedTags.includes(tag.id.toString()));
    })
    .sort((a, b) => {
      switch (selectedSort) {
        case 'newest':
          return a.created_at < b.created_at ? 1 : -1;
        case 'oldest':
          return a.created_at > b.created_at ? 1 : -1;
        case 'most_words':
          return a.word_count < b.word_count ? 1 : -1;
        case 'fewest_words':
          return a.word_count > b.word_count ? 1 : -1;
      }
    })
    .map((entry) => {
      return <EntryListItem key={entry.id} entry={entry} />;
    });

  // TODO: This makes the header a bit taller than it is on the other pages
  const titleExtra = (
    <Group align="center">
      <Stack gap={2}>
        <Text size="xs" c="dimmed" fw="500">
          Total Entries
        </Text>

        <Text size="md">{query.data.meta.entries_total}</Text>
      </Stack>
      <Stack gap={2}>
        <Text size="xs" c="dimmed" fw="500">
          # of Words
        </Text>

        <Text size="md">{query.data.meta.entries_words}</Text>
      </Stack>
    </Group>
  );

  return (
    <Page
      title={
        <Group justify="space-between" align="center">
          <PageTitle title="All entries" />

          {titleExtra}
        </Group>
      }
    >
      <Grid>
        <Grid.Col span={{ base: 12, md: 4 }}>
          <Select
            data={[
              {
                value: 'newest',
                label: 'Newest',
              },
              {
                value: 'oldest',
                label: 'Oldest',
              },
              {
                value: 'most_words',
                label: 'Most words',
              },
              {
                value: 'fewest_words',
                label: 'Fewest words',
              },
            ]}
            onChange={(value) => setSelectedSort(value as Sort)}
            placeholder="Sort by"
            value={selectedSort}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 4 }}>
          <MultiSelect
            clearable
            data={
              tagsQuery.data?.items?.map((t) => ({
                value: t.id.toString(),
                label: t.name,
              })) || []
            }
            hidePickedOptions
            onChange={(values) => setSelectedTags(values)}
            placeholder="Filter by tags"
            searchable
            value={selectedTags}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 4 }}>
          <DatePickerInput
            onChange={setSelectedDateRange}
            placeholder="Select date range"
            type="range"
            value={selectedDateRange}
          />
        </Grid.Col>
      </Grid>

      {entries && entries.length > 0 ? (
        entries
      ) : (
        <NoItemsFound
          Icon={BookOpenIcon}
          fullHeight
          title="No entries found"
          subtitle="It looks like you haven't created any entries yet, or your current filter doesn't match any existing entries."
          actions={
            <>
              <Button component={Link} leftSection={<PlusIcon width={16} />} to="/entries/new">
                Create a new entry
              </Button>
              <Button onClick={() => clearFilters()} variant="outline">
                Clear filters
              </Button>
            </>
          }
        />
      )}
    </Page>
  );
}
