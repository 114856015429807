import { Stack } from '@mantine/core';
import type React from 'react';

interface FormActionsProps {
  children: React.ReactNode;
}

export default function FormActions({ children }: FormActionsProps) {
  return (
    <Stack justify="end" gap={8} py={8}>
      {children}
    </Stack>
  );
}
