import { PlusIcon } from '@heroicons/react/16/solid';
import { TagIcon } from '@heroicons/react/24/outline';
import { Badge, Button } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';
import { type Items, type Tag, fetcher } from '../../api';
import ErrorScreen from '../ErrorScreen.tsx';
import LoadingScreen from '../LoadingScreen';
import NoItemsFound from '../NoItemsFound.tsx';
import Page from '../Page';
import PageTitle from '../PageTitle';
import { StackedListItem } from '../StackedListItem';
import TagBadge from '../TagBadge.tsx';

export default function TagsScreen() {
  const query = useQuery<Items<Tag, void>>({
    queryKey: ['api', 'v1', 'tags'],
    queryFn: () => fetcher('/api/v1/tags'),
  });

  if (query.isLoading) {
    return <LoadingScreen />;
  }

  if (query.isError) {
    return <ErrorScreen error={query.error} />;
  }

  const allTags = query.data?.items || [];

  const tags = allTags.map((tag) => {
    return (
      <StackedListItem
        key={tag.id}
        href={`/tags/${tag.id}`}
        title={<TagBadge tag={tag} />}
        titleRight={
          <Badge color="gray" variant="light">
            {tag.word_count} {pluralize('word', tag.word_count)}
          </Badge>
        }
        subtitle={
          <Badge color="blue" variant="light">
            {tag.entry_count} {pluralize('post', tag.entry_count)}
          </Badge>
        }
        subtitleRight={
          <Badge color="gray" variant="light">
            {tag.char_count} {pluralize('character', tag.char_count)}
          </Badge>
        }
      />
    );
  });

  return (
    <Page title={<PageTitle title="All tags" />}>
      {tags}

      {allTags.length === 0 && (
        <NoItemsFound
          Icon={TagIcon}
          fullHeight
          title="No tags found"
          subtitle="It looks like you haven't created any tags yet."
          actions={
            // TODO: This route doesn't exist
            <Button component={Link} leftSection={<PlusIcon width={16} />} to="/tags/new">
              Create a new tag
            </Button>
          }
        />
      )}
    </Page>
  );
}
