import { Badge, Group as MantineGroup, Text, rgba } from '@mantine/core';
import { Link } from 'react-router-dom';

interface Tag {
  id: number;
  name: string;
  colour_background: string;
  colour_foreground: string;
}

interface Props {
  tag: Tag;
}

interface GroupProps {
  tags: Tag[];
}

function Group({ tags }: GroupProps) {
  if (!tags || tags.length === 0) {
    return (
      <Text c="dimmed" size="sm">
        No tags selected
      </Text>
    );
  }

  return (
    <MantineGroup gap={4}>
      {tags.map((tag) => (
        <TagBadge key={tag.id} tag={tag} />
      ))}
    </MantineGroup>
  );
}

function TagBadge({ tag }: Props) {
  return (
    <Badge
      component={Link}
      to={`/tags/${tag.id}`}
      radius="sm"
      style={{ backgroundColor: tag.colour_background, color: tag.colour_foreground, cursor: 'pointer' }}
      variant="outline"
      styles={{
        root: {
          borderColor: rgba(tag.colour_foreground, 0.2),
        },
      }}
    >
      {tag.name}
    </Badge>
  );
}

TagBadge.Group = Group;

export { TagBadge as default };
