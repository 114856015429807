import { Button } from '@mantine/core';
import type React from 'react';

interface FormSubmitProps {
  children: React.ReactNode;
  fullWidth?: boolean;
  loading: boolean;
}

export default function FormSubmit({ children, fullWidth, loading }: FormSubmitProps) {
  return (
    <Button fullWidth={fullWidth} loading={loading} type="submit" disabled={loading} variant="solid">
      {children}
    </Button>
  );
}
