import { PlusIcon } from '@heroicons/react/16/solid';
import { EllipsisHorizontalCircleIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon, ExclamationTriangleIcon, PencilIcon, XCircleIcon } from '@heroicons/react/24/solid';
import {
  ActionIcon,
  Alert,
  Box,
  Button,
  Group,
  type MantineColor,
  Paper,
  Progress,
  Stack,
  Text,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import type React from 'react';
import { Link } from 'react-router-dom';
import { type Goal, type Items, fetcher } from '../../api.ts';
import ErrorScreen from '../ErrorScreen.tsx';
import Page from '../Page';
import PageTitle from '../PageTitle';
import { getGoalProgress } from './goalHelpers.ts';

export default function GoalsScreen() {
  const query = useQuery<Items<Goal, void>>({
    queryKey: ['api', 'v1', 'goals'],
    queryFn: () => fetcher('/api/v1/goals'),
  });

  if (query.isError) {
    return <ErrorScreen error={query.error} />;
  }

  const goals = query.data;
  if (!goals) {
    // TODO
    return <p>Loading...</p>;
  }

  return (
    <Page
      title={
        <Group align="center" justify="space-between">
          <PageTitle title="Goals" />

          <Box>
            <Button component={Link} variant="light" leftSection={<PlusIcon width={16} />} to="/goals/new">
              Set a new goal
            </Button>
          </Box>
        </Group>
      }
      loading={query.isLoading}
    >
      <Alert color="yellow" title="Warning" icon={<ExclamationTriangleIcon />} mb={16}>
        Goals are still in development and may not work as expected!
      </Alert>

      <Stack gap={0}>
        {goals.items.map((goal) => (
          <GoalItem key={goal.id} goal={goal} />
        ))}
      </Stack>
    </Page>
  );
}

interface GoalItemProps {
  goal: Goal;
}

const GoalItem = ({ goal }: GoalItemProps) => {
  switch (goal.type) {
    case 'project_completion':
      return <ProjectCompletionGoalItem goal={goal} />;
    case 'word_count':
      return <WordCountGoalItem goal={goal} />;
    case 'entry_count':
      return <EntryCountGoalItem goal={goal} />;
    default:
      // TODO
      return <></>;
  }
};

interface ProjectCompletionGoalItemProps {
  goal: Goal;
}

const ProjectCompletionGoalItem = ({ goal }: ProjectCompletionGoalItemProps) => {
  // TODO: This component should display whether the project was completed after the deadline or not
  const progress = getGoalProgress(goal);

  const actions = (
    <Tooltip label={`Mark as ${progress.completed ? 'incomplete' : 'complete'}`}>
      <ActionIcon p={4} variant="subtle" color="gray" size="lg">
        {progress.completed ? <XCircleIcon /> : <CheckCircleIcon />}
      </ActionIcon>
    </Tooltip>
  );

  return (
    <ProgressCard
      actions={actions}
      completed={progress.completed}
      progress={progress.progress}
      title={progress.title}
      subtitle={progress.subtitle}
    />
  );
};

interface WordCountGoalItemProps {
  goal: Goal;
}

const WordCountGoalItem = ({ goal }: WordCountGoalItemProps) => {
  const progress = getGoalProgress(goal);
  return (
    <ProgressCard
      completed={progress.completed}
      progress={progress.progress}
      title={progress.title}
      subtitle={progress.subtitle}
    />
  );
};

interface ProgressCardProps {
  actions?: React.ReactNode;
  completed: boolean;
  progress: number;
  title: string;
  subtitle: string;
}

function ProgressCard({ actions, completed, progress, title, subtitle }: ProgressCardProps) {
  const theme = useMantineTheme();

  const getProgressColor = (progress: number): MantineColor => {
    if (progress === 100) {
      return theme.colors.green[9];
    }

    if (progress > 25) {
      return theme.colors.yellow[9];
    }

    return theme.colors.red[9];
  };

  return (
    <Paper mb={12} p={24} radius="md" withBorder variant="outlined">
      <Group justify="space-between">
        <Group gap={8}>
          {completed ? (
            <CheckCircleIcon color={theme.colors.green[9]} width={24} />
          ) : (
            <EllipsisHorizontalCircleIcon color={theme.colors.gray[6]} width={24} />
          )}

          <Title order={4}>{title}</Title>
        </Group>

        <Group gap={2}>
          {actions}

          <Tooltip label="Edit goal">
            <ActionIcon p={5} variant="subtle" color="gray" size="lg">
              <PencilIcon />
            </ActionIcon>
          </Tooltip>
        </Group>
      </Group>

      <Text size="sm">{subtitle}</Text>

      <Box mt={12}>
        <Progress value={progress} color={getProgressColor(progress)} variant="soft" />
      </Box>
    </Paper>
  );
}

interface EntryCountGoalItemProps {
  goal: Goal;
}

const EntryCountGoalItem = ({ goal }: EntryCountGoalItemProps) => {
  const progress = getGoalProgress(goal);
  return (
    <ProgressCard
      completed={progress.completed}
      progress={progress.progress}
      title={progress.title}
      subtitle={progress.subtitle}
    />
  );
};
