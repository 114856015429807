import { createFormContext } from '@mantine/form';
import type { EntryType } from '../../api.ts';

interface FormValues {
  title: string | null;
  type: EntryType;
  content: string;
  serial: string | null;
  tags: string[];
}

type TransformedFormValues = {
  title: string | null;
  type: EntryType;
  content: string;
  serial: number | null;
  tags: number[];
};

export const [FormProvider, useFormContext, useForm] = createFormContext<
  FormValues,
  (values: FormValues) => TransformedFormValues
>();
