import { Box, Loader, Paper, Stack, Text, TextInput } from '@mantine/core';
import type { TransformedValues } from '@mantine/form';
import { useInterval } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  MDXEditor,
  Separator,
  UndoRedo,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  toolbarPlugin,
} from '@mdxeditor/editor';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import pluralize from 'pluralize';
import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { type Entry, type EntryType, fetcher } from '../../api.ts';
import Editor from './Editor.tsx';
import { FormProvider, useForm } from './EntryFormContext.ts';
import '@mdxeditor/editor/style.css';
import classes from './EntryEdit.module.css';

interface EntryEditProps {
  content: string | null;
  id: number | null;
  title: string | null;
  type: EntryType | null;
  serial: number | null;
  tags: number[];
}

export function EntryEdit(props: EntryEditProps) {
  const queryClient = useQueryClient();
  const [id, setId] = useState<number | null>(props.id);
  const [savedAt, setSavedAt] = useState<Date | null>(null);

  const saveEntry = useCallback(async () => {
    const values = form.getTransformedValues();
    if (id === null && values.content.trim().length === 0) {
      // Don't save empty entries (i.e. if the user just opened the editor and then closed it)
      return;
    }

    if (id === null) {
      await createEntry.mutateAsync(values);
    } else {
      // TODO: Should this even be a patch like this?
      const operations: { op: string; path: string; value?: unknown }[] = [
        {
          op: 'replace',
          path: '/content',
          value: values.content,
        },
        {
          op: 'replace',
          path: '/tags',
          value: values.tags.map((tag) => ({ id: tag })),
        },
        {
          op: 'replace',
          path: '/title',
          value: values.title,
        },
        {
          op: 'replace',
          path: '/type',
          value: values.type,
        },
      ];

      if (values.serial) {
        operations.push({
          op: 'replace',
          path: '/series',
          value: { id: values.serial },
        });
      } else {
        operations.push({
          op: 'remove',
          path: '/series',
        });
      }

      await patchEntry.mutateAsync(operations);
    }
  }, [id]);

  const triggerDebouncedSave = useDebouncedCallback(saveEntry, 1000, {
    maxWait: 5000,
  });

  // Save the entry every 30 seconds
  useInterval(() => triggerDebouncedSave(), 30_000, {
    autoInvoke: true,
  });

  const form = useForm({
    // TODO: This should be uncontrolled, but list fields (tags) don't seem to get a value so adding one doesn't update the select box
    mode: 'controlled',
    initialValues: {
      title: props.title,
      type: props.type || 'freeform',
      content: props.content || '',
      serial: props.serial ? props.serial.toString() : null,
      tags: props.tags.map((t) => t.toString()) || [],
    },
    onValuesChange: triggerDebouncedSave,
    transformValues: (values) => ({
      ...values,
      serial: values.serial ? Number.parseInt(values.serial) : null,
      tags: values.tags.map((tag) => Number.parseInt(tag)),
    }),
  });

  const createEntry = useMutation({
    mutationFn: (values: TransformedValues<typeof form>) => {
      return fetcher<Entry>('/api/v1/entries', {
        method: 'POST',
        body: JSON.stringify({
          content: values.content,
          title: values.title,
          type: values.type,
          series: {
            id: values.serial,
          },
          tags: [...values.tags.map((id) => ({ id: id }))],
        }),
      });
    },
    onError: (error) => {
      console.error(error);
      notifications.show({
        autoClose: false,
        title: 'Error',
        message:
          'Failed to create the entry. Please copy your content, and refresh the page to try creating the entry again!',
        color: 'red',
      });
    },
    onSuccess: async (entry) => {
      setId(entry.id);
      await queryClient.invalidateQueries({
        queryKey: ['api', 'v1', 'entries', id],
      });
      setSavedAt(new Date());
    },
  });

  const patchEntry = useMutation({
    mutationFn: (patch: object[]) => {
      return fetcher(`/api/v1/entries/${id}`, {
        method: 'PATCH',
        body: JSON.stringify(patch),
      });
    },
    onError: (error) => {
      console.error(error);
      notifications.show({
        autoClose: false,
        title: 'Error',
        message:
          'Failed to save the entry. Please copy your content, and refresh the page to try saving the entry again!',
        color: 'red',
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['api', 'v1', 'entries', id],
      });

      setSavedAt(new Date());
    },
  });

  // Whenever the user tries to close the windows when the editor is open, ask to make sure
  // TODO: Be more clever here, and only display the alert if the content has been modified since the last successful save
  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);
    return () => window.removeEventListener('beforeunload', handleUnload);
  }, []);

  // Whenever the user changes windows, save the content
  useEffect(() => {
    window.addEventListener('blur', triggerDebouncedSave);
    return () => window.removeEventListener('blur', triggerDebouncedSave);
  }, [triggerDebouncedSave]);

  // Whenever the user switches tabs, or otherwise hides the window (i.e. mobile browsers), save the content
  useEffect(() => {
    window.addEventListener('visibilitychange', triggerDebouncedSave);
    return () => window.removeEventListener('visibilitychange', triggerDebouncedSave);
  }, [triggerDebouncedSave]);

  // Whenever the user's connectivity comes back, save the content
  useEffect(() => {
    window.addEventListener('online', triggerDebouncedSave);
    return () => window.removeEventListener('online', triggerDebouncedSave);
  }, [triggerDebouncedSave]);

  const handleUnload = async (e: Event) => {
    e.preventDefault();
    await saveEntry();
  };

  // TODO: Implement mobile-friendly unload handling: https://www.igvita.com/2015/11/20/dont-lose-user-and-app-state-use-page-visibility/

  const content = form.getValues().content;
  const wordCount = content.trim().length === 0 ? 0 : content.split(/\s+\b/).length;

  // TODO: Notify if the date crosses over into tomorrow (or past the user's set "end of day" threshold)
  // TODO: Store content in local storage or something until it's saved

  const saving = createEntry.isPending || patchEntry.isPending;
  const savingElement = saving ? (
    <Loader size={18} />
  ) : savedAt ? (
    // TODO: If saving errors, this says the wrong thing!
    <Text size="sm">Saved at {savedAt.toLocaleTimeString()}</Text>
  ) : (
    <Text size="sm">Not saved yet</Text>
  );

  const contentInputProps = form.getInputProps('content');

  const editor = (
    <Stack gap={8}>
      <TextInput
        key={form.key('title')}
        {...form.getInputProps('title')}
        placeholder="Enter a title for your entry (optional)"
      />

      <Box>
        <Paper radius="sm" withBorder>
          <MDXEditor
            contentEditableClassName={classes.prose}
            key={form.key('content')}
            autoFocus
            placeholder="Start writing something..."
            trim
            markdown={contentInputProps.value}
            onChange={contentInputProps.onChange}
            onBlur={contentInputProps.onBlur}
            onError={(payload) => {
              console.error(payload);

              notifications.show({
                autoClose: false,
                title: 'Error',
                message:
                  "There is a problem with this entry's content, and further changes may not be saved. Please contact support@writual.app",
                color: 'red',
              });
            }}
            plugins={[
              headingsPlugin(),
              listsPlugin(),
              quotePlugin(),
              toolbarPlugin({
                toolbarClassName: classes.toolbar,
                toolbarContents: () => (
                  <>
                    <UndoRedo />
                    <BoldItalicUnderlineToggles options={['Bold', 'Italic']} />
                    <BlockTypeSelect />

                    <Box ml="auto">
                      <Text size="sm">
                        {wordCount} {pluralize('word', wordCount)}
                      </Text>
                    </Box>
                    <Separator />
                    <Box mr={8}>{savingElement}</Box>
                  </>
                ),
              }),
            ]}
          />
        </Paper>
      </Box>
    </Stack>
  );

  return (
    <FormProvider form={form}>
      <Editor id={id}>{editor}</Editor>
    </FormProvider>
  );
}
